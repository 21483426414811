import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	CompEventsRegistrarSym,
	Props,
	ViewModeSym,
	BrowserWindowSymbol,
	ComponentsStylesOverridesSymbol,
} from '@wix/thunderbolt-symbols'
import { MenuContainerData, MenuContainerFactory } from './types'
import { Animations } from 'feature-animations'
import { SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import { AnimationHandler } from './AnimationHandler'
import { isSSR } from '@wix/thunderbolt-commons'

const menuContainer: MenuContainerFactory = (
	compEventsRegistrar,
	propsStore,
	viewMode,
	window,
	animations,
	siteScrollBlocker,
	featureConfig,
	componentsStylesOverrides
) => {
	const animationHandler = animations && AnimationHandler(animations)

	const onToggleClick = (
		menuContainerId: string,
		menuContainerData: MenuContainerData,
		immediate: boolean = false
	) => {
		const menuContainerTogglesIds = menuContainerData.toggleIds
		const menuContainerProps = propsStore.get(menuContainerId)
		const isOpen = !menuContainerProps.isOpen
		const shouldUpdateVisibility = isOpen || immediate || !animationHandler

		const isMobile = viewMode === 'mobile'

		siteScrollBlocker && siteScrollBlocker.setSiteScrollingBlocked(isOpen, menuContainerId)
		!immediate &&
			animationHandler &&
			animationHandler.animate(menuContainerId, menuContainerData, isOpen, (reversed: boolean) => {
				const isClosed = reversed ? isOpen : !isOpen
				if (isClosed) {
					propsStore.update({
						[menuContainerId]: {
							isVisible: false,
						},
					})
				}
			})

		const menuTogglesProps = menuContainerTogglesIds.reduce((result, toggleId) => {
			result[toggleId] = { isOpen }
			return result
		}, {} as { [toggleId: string]: { [isOpen: string]: boolean } })

		propsStore.update({
			[menuContainerId]: {
				isOpen,
				...(shouldUpdateVisibility && { isVisible: isOpen }),
			},
			...menuTogglesProps,
			...(isOpen &&
				componentsStylesOverrides.getUpdatedStyle({
					[menuContainerId]: {
						'--menu-height':
							!isSSR(window) && isMobile ? window.getComputedStyle(document.body).height : '100vh',
					},
				})),
		})
	}

	const clickTogglesByMenuContainerId = (menuContainerId: string, openMenu: boolean) => {
		const isMenuOpen = propsStore.get(menuContainerId).isOpen
		const menuContainerData = featureConfig[menuContainerId]

		if ((openMenu && !isMenuOpen) || (!openMenu && isMenuOpen)) {
			onToggleClick(menuContainerId, menuContainerData)
		}
	}

	return {
		getSdkHandlers: () => ({
			openMenuContainer: (menuContainerId: string) => {
				clickTogglesByMenuContainerId(menuContainerId, true)
			},
			closeMenuContainer: (menuContainerId: string) => {
				clickTogglesByMenuContainerId(menuContainerId, false)
			},
		}),

		pageWillMount: async () => {
			Object.entries(featureConfig).forEach(([menuContainerId, menuContainerData]) => {
				const menuTogglesIds = menuContainerData.toggleIds

				const onKeyDown = (e: Event) => {
					const keyboardEvent = e as KeyboardEvent
					if (keyboardEvent.key === 'Enter' || keyboardEvent.key === ' ') {
						onToggleClick(menuContainerId, menuContainerData)
					}
				}

				const onMenuContainerClick = (e: Event) => {
					const anchor = getClosestAnchorAncestor(e.target as HTMLElement)
					if (anchor) {
						onToggleClick(menuContainerId, menuContainerData, true)
					}
				}

				compEventsRegistrar.register(menuContainerId, 'onClick', onMenuContainerClick)
				menuTogglesIds.forEach((menuToggleId) => {
					compEventsRegistrar.register(menuToggleId, 'onKeyDown', onKeyDown)
					compEventsRegistrar.register(menuToggleId, 'onClick', () => {
						onToggleClick(menuContainerId, menuContainerData)
					})
				})
			})
		},
	}
}

const getClosestAnchorAncestor = (element: HTMLElement | null): HTMLElement | null => {
	if (!element || !element.tagName) {
		return null
	}
	if (element.tagName.toLowerCase() === 'a') {
		return element
	}
	return getClosestAnchorAncestor(element.parentNode as HTMLElement) // TODO: after the infra will load the closest api, use it instead.
}

export const MenuContainer = withDependencies(
	[
		CompEventsRegistrarSym,
		Props,
		ViewModeSym,
		BrowserWindowSymbol,
		optional(Animations),
		optional(SiteScrollBlockerSymbol),
		named(PageFeatureConfigSymbol, 'menuContainer'),
		ComponentsStylesOverridesSymbol,
	],
	menuContainer
)
